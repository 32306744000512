import { render, staticRenderFns } from "./danhao.vue?vue&type=template&id=e57e156e&scoped=true&"
import script from "./danhao.vue?vue&type=script&lang=js&"
export * from "./danhao.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e57e156e",
  null
  
)

export default component.exports